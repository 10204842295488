import React from "react"

export function GangrelHaven() {
    return (
        <React.Fragment>
            <p>Gangrel often lair where they can, taking refuge when the sun threatens to rise. Those who do maintain permanent havens often lean toward the util- itarian: Everything from a cave to a covered alley to an illegal squat may serve as a Gangrel haven, usually with little demarcating them as any sort of personal territory (until it's too late for the unfortunate interloper).</p>
        </React.Fragment>
    )
}

export function GangrelOrganization() {
    return (
        <React.Fragment>
           <p>Regional groups of Gangrel occasionally assemble in convocations that draw from ethnic or cultural influences. These are informal affairs, geared more toward sharing information and revelry than advancing any cogent agenda. Aside from these infrequent gatherings, almost all Gangrel organization is very local where it exists at all, from pairs of sire and childe through terrifying packs centered around one accomplished Outlander.</p>
        </React.Fragment>
    )
}

export function GangrelWeakness() {
    return (
        <React.Fragment>
           <p>Every time a Gangrel frenzies, she acquires a temporary animal characteristic (which may replace an existing temporary one). A patch of fur, a brief torpor after feeding, or skittishness around crowds — all of these may mar an Outlander after frenzy. Characteristics acquired in Gangrel frenzies need not only be physical - they can be behavioral as well. Players should work with the Storyteller to determine what new animal trait is acquired (whether the frenzy involved the fight-or-flight impulse may be relevant). </p>
           <p>Over time, or in an exceptional situation, a particular animal feature may become permanent, with the next frenzy adding a new feature. A good guideline is to require each frenzy-gained trait to have some effect grounded in system terms (such as the temporary reduction of Social Attribute dots or a permanent loss of Humanity), though some Storytellers may allow narrative-only traits that can shape the story.</p>
        </React.Fragment>
    )
}

export function GangrelCreation() {
    return (
        <React.Fragment>
           <p>Sufficiency is the Gangrel hallmark, and many have outsider or loner personality archetypes. Physical Attributes are far and away most frequent, as are Talents with a smattering of Skills and Knowledges. Many Gangrel focus on Disciplines rather than Backgrounds, preferring to rely on themselves more than others. Gangrel almost never have significant Resources, Influence, or Retainers.</p>
        </React.Fragment>
    )
}

export function GangrelBackground() {
    return (
        <React.Fragment>
            <p>Gangrel sire childer like they seek prey: after long hunts during which the prospective childe doesn't even know she's being followed. Creating a fledgling means sharing limited resources, so each sirechilde relationship is unique and significant. Outlanders Embrace because they choose an individual, not out of whim or recklessness. Those who earn their attention are hardy, whether physically or emotionally.</p>
        </React.Fragment>
    )
}

export function GangrelAppearance() {
    return (
        <React.Fragment>
           <p>Personal presentation is often not high on the list of many Gangrel priorities, and a Gangrel's appearance is often more a matter of circumstance than it is of active decision. The Clan's weakness can contribute a great deal to their appearance, as does an extended unlife in the places where they make their havens, which are frequently short of modern conveniences.</p>
        </React.Fragment>
    )
}

export function GangrelDescription() {
    return (
        <React.Fragment>
           <p>A glint of red eyes in the darkness, the scent of a predator's musk, a flash of fangs, the sound of flesh tearing: These mark the presence of the Gangrel. More than any other Clan, the Gangrel resemble the beasts associated with the legends of vampires: bats, wolves, and other creatures of darkness. Indeed, the Outlanders may develop the ability to transform themselves into these and other, more primal forms. </p>
           <p>The Gangrel have other characteristics in common with animals as well. Many shun the elaborate social constructs of both Kindred and kine. A number of them prefer to move alone or as a member of small packs or coteries. Most are tough and, when pressed, ferocious. And when Gangrel succumb to the depredations of the Beast, they are left with some feature redolent of the animal kingdom. </p>
           <p>As a Clan, the Gangrel are wary and aloof. Most would prefer to spend their nights stalking prey or wilding on the rooftops than minding Princely edicts or lobbying for recognition of domain. Theirs is a tense relationship with vampire society, and Outlanders are among the most frequent to turn Anarch or Autarkis. In some localities, the Gangrel have collectively abandoned membership in any Sect — insofar as the Gangrel truly ever do anything collectively. </p>
           <p>The Jyhad seems less pronounced among the Gangrel than it is among other Clans, and the Outlanders have little regard for the eternal conflict. Differences between Gangrel more often fall along the lines of domain and feeding rights than they do on generational mistrust, and the things that concern young Outlanders are matters that concern Gangrel ancillae and even elders. Still, one would be hard-pressed to consider the relationship among Gangrel of different generations amicable, except in isolated sire-childe situations. The Beast is ever suspicious of those who would take sustenance from it.</p>
        </React.Fragment>
    )
}