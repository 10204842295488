import anarchlogo from './sect_data/Anarch.png';
import camarillalogo from './sect_data/Camarilla.png';
import sabbatlogo from './sect_data/Sabbat.png';

export const sects = [
    {
        name: "Camarilla",
        logo: camarillalogo,
        description: ""
    },
    {
        name: "Anarch",
        logo: anarchlogo,
        description: ""
    },
    {
        name: "Sabbat",
        logo: sabbatlogo,
        description: ""
    }
];